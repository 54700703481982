import React, { useEffect } from 'react'
import Orsi from '../components/Orsi'
import Deck from '../components/Deck'

import { useAuth } from '../providers/AuthContext';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function HomePage() {

    const { currentUser } = useAuth();
    const navigate = useNavigate(); // Initialize the useNavigate hook

    useEffect(() => {
        if (!currentUser) {
            navigate('/login'); // Redirect to login page if currentUser is not available
        }
    }, [currentUser, navigate]);

    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Deck /> :
        </div>
    )
}

export default HomePage
