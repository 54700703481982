import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInAnonymously, GoogleAuthProvider, EmailAuthProvider, PhoneAuthProvider } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import { ROUTES } from '../resources/routes-constants';
import { useAuth } from '../providers/AuthContext'; 


function LoginPage() {
    const navigate = useNavigate();
    const { setCurrentUser } = useAuth();

    const generateRandomName = () => {
        const adjectives = ["Adventurous", "Brave", "Curious", "Daring", "Energetic"];
        const animals = ["Lion", "Tiger", "Bear", "Wolf", "Eagle"];
        const adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
        const animal = animals[Math.floor(Math.random() * animals.length)];
        return `${adjective} ${animal}`;
    };

    useEffect(() => {
        const auth = getAuth();

        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);

        const uiConfig = {
            callbacks: {
                signInSuccessWithAuthResult: (authResult: any, redirectUrl: any) => {
                    
                    navigate(ROUTES.HOMEPAGE_ROUTE);
                    return false;
                },
                uiShown: () => {
                    const loader = document.getElementById('loader');
                    if (loader) loader.style.display = 'none';
                }
            },
            signInFlow: 'popup',
            signInSuccessUrl: ROUTES.HOMEPAGE_ROUTE,
            signInOptions: [
                GoogleAuthProvider.PROVIDER_ID,
                EmailAuthProvider.PROVIDER_ID,
                PhoneAuthProvider.PROVIDER_ID,
            ],
            tosUrl: '<your-tos-url>',
            privacyPolicyUrl: '<your-privacy-policy-url>'
        };

        // Initializes FirebaseUI
        ui.start('#firebaseui-auth-container', uiConfig);

        // Cleanup function to delete the UI instance upon component unmount
        return () => { ui.delete() };
    }, [navigate]); // Dependency array includes navigate to re-run the effect if navigate changes

    const handleAnonymousSignIn = () => {
        const auth = getAuth();
        signInAnonymously(auth)
          .then((result) => {
            // Update the current user in context
            setCurrentUser(result.user);
            // Optionally update the profile with a generated name here as previously discussed
            navigate(ROUTES.HOMEPAGE_ROUTE);
          })
          .catch((error) => {
            console.error(error);
          });
      };

    return (
        <div>
            <h1>Login Please</h1>
            <div id="firebaseui-auth-container"></div>
            <button onClick={handleAnonymousSignIn}>Continue Anonymously</button>
            <div id="loader">Loading...</div>
        </div>
    );
}

export default LoginPage;
