import React, { useState } from 'react';
import FibonacciCards from './FibonacciCards'; // Adjust the import path as necessary

const Deck = () => {
  const [isFlipped, setIsFlipped] = useState(true);
  const [selectedCard, setSelectedCard] = useState<number | null>(null);
  const catGifUrl = "https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExenVvcHV5NTAyYXY2N3BvN2w2em5tb2pxbGl0cW1yd3phbGh5Y3lheSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/AJ85Ie15uFoYU5B0vN/giphy.gif";

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
    // Optionally, reset selected card when flipping
    setSelectedCard(null);
  };

  const handleSelectCard = (number: number) => { 
      setSelectedCard(number);
  };

  return (
    <div className="deck">
      <button className="button button-primary" onClick={handleFlip}>Start</button>
      <FibonacciCards count={7} isFlipped={isFlipped} onSelectCard={handleSelectCard} selectedCard={selectedCard} />
      {isFlipped && <img src={catGifUrl} alt="Animated cat" className="cat-animation" style={{width: '80%'}} />}
    </div>
  );
};

export default Deck;
