// FibonacciCards.jsx
import React from 'react';
import FibonacciCard from './FibonacciCard'; // Adjust the import path based on your project structure

interface FibonacciCardsProps {
  count: number;
  isFlipped: boolean;
  onSelectCard: (number: number) => void;
  selectedCard: number | null;
}

class FibonacciCards extends React.Component<FibonacciCardsProps> {
  generateFibonacciNumbers(n: number): number[] {
    const fib = [1, 2]; // Starting values
    for (let i = 2; i < n; i++) {
      fib[i] = fib[i - 1] + fib[i - 2]; 
    }
    return fib;
  }

  render() {
    const { count, isFlipped, onSelectCard, selectedCard } = this.props;
    const cards = this.generateFibonacciNumbers(count).map((number, index) => (
      <FibonacciCard
        key={index}
        number={number}
        isFlipped={isFlipped}
        isSelected={selectedCard === number}
        onSelect={(number: number) => onSelectCard(number)}
      />
    ));

    return <div style={{ padding: '20px' }}>{cards}</div>;
  }
}

export default FibonacciCards;
