import React, { useState } from 'react';

interface FibonacciCardProps {
  number: number;
  isFlipped: boolean;
  isSelected:boolean;
  onSelect: any;
}

const FibonacciCard: React.FC<FibonacciCardProps> = ({ number, isFlipped, isSelected, onSelect}) => {
  const cardBackClass = !isSelected ? "card-back" : "card-back selected";

  return (
    <div className="card-container">
      {isFlipped ? (
        <div className={cardBackClass} onClick={() => onSelect(isSelected ? null : number)}>
          {number}
        </div>
      ) : (
        <div className="card-front">
          Flip
        </div>
      )}
    </div>
  );
};

export default FibonacciCard;
