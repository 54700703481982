import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import LoginPage from './pages/LoginPage'; // Assume you have this component
import { ROUTES } from './resources/routes-constants';
import './styles/main.sass';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { AuthProvider } from './providers/AuthContext';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBURK_AHg8QSrP6C7Tt66SrlFLk4G-K6iI",
    authDomain: "gritty.digital",
    projectId: "onlypp",
    storageBucket: "onlypp.appspot.com",
    messagingSenderId: "381860474862",
    appId: "1:381860474862:web:7c8ef04a1858f2fb7368ff",
    measurementId: "G-4RS29S132X"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

const RootComponent: React.FC = () => {
    return (
        <Router>
            <AuthProvider>
                <Routes>
                    <Route path="*" element={<NotFoundPage />} />
                    <Route path={ROUTES.HOMEPAGE_ROUTE} element={<HomePage />} />
                    <Route path={ROUTES.LOGIN} element={<LoginPage />} /> {/* Adjusted this line */}
                </Routes>
            </AuthProvider>
        </Router>
    );
}

export default RootComponent;
